import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { provideNgxMask } from 'ngx-mask';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './core/interceptor/http-interceptor';
import { ErrorPageComponent } from './modules/error-page/error-page.component';
import { MainContentComponent } from './modules/main-content/main-content.component';
import { NoBrokerComponent } from './modules/no-broker/no-broker.component';
import { StepperFormModule } from './modules/stepper-form/stepper-form.module';

@NgModule({ declarations: [
        AppComponent,
        MainContentComponent,
        ErrorPageComponent,
        NoBrokerComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatMenuModule,
        MatProgressBarModule,
        MatButtonModule,
        StepperFormModule,
        MatProgressSpinnerModule,
        StoreModule.forRoot({}),
        EffectsModule.forRoot([])], providers: [
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { displayDefaultIndicatorType: false },
        },
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'accent' },
        },
        provideNgxMask(),
        { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
